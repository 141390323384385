<template>
  <div
    class="tw-w-full tw-h-full"
    @click="$emit('click')"
  >
    <div
      class="tw-relative"
      :style="imageStyle"
    >
      <span
        v-if="imageSubtitle"
        :class="imageSubtitleClass"
      >
        {{ imageSubtitle }}
      </span>
      <img
        :class="imageClass"
        loading="lazy"
        :src="imageUrl"
      >
    </div>
    <div :style="labelStyle">
      <h4
        :class="titleClass"
      >
        {{ title }}
      </h4>
      <h5
        v-if="subtitle"
        :class="subtitleClass"
      >
        {{ subtitle }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageWithLabel',

  props: {
    hasRoundedCorners: {
      type: Boolean,
      default: false,
    },
    imageSubtitle: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },

  emits: ['click'],

  computed: {
    imageClass() {
      return [
        'tw-w-full tw-object-cover tw-cursor-pointer',
        this.large ? 'tw-h-full' : 'tw-h-48',
        this.hasRoundedCorners ? 'tw-rounded-md' : null,
      ];
    },

    imageSubtitleClass() {
      return [
        'tw-absolute tw-bottom-0 tw-left-0',
        'tw-w-full tw-h-14 tw-pt-8 tw-pr-2',
        'tw-bg-gradient-to-t tw-from-black',
        'tw-text-xs tw-font-semibold tw-text-right tw-text-white',
        this.hasRoundedCorners ? 'tw-rounded-b-md tw-overflow-hidden' : null,
      ];
    },

    imageStyle() {
      return {
        height: `calc(100% - ${this.labelHeight}rem)`,
      };
    },

    labelHeight() {
      return this.subtitle ? 2.75 : 2.25;
    },

    labelStyle() {
      return {
        height: `${this.labelHeight}rem`,
      };
    },

    subtitleClass() {
      return [
        'tw-text-xs tw-text-center tw-font-normal',
        'tw-px-2 tw-pb-1',
        'tw-overflow-hidden tw-whitespace-nowrap tw-overflow-ellipsis',
        'text-light-color',
      ];
    },

    titleClass() {
      return [
        'tw-text-sm tw-text-center tw-font-normal',
        this.subtitle ? 'tw-pt-1' : 'tw-py-2',
        'tw-px-2',
        'tw-overflow-hidden tw-whitespace-nowrap tw-overflow-ellipsis',
      ];
    },
  },
};
</script>
